export default {
    namespaced: true,
    state: () => ({
        tournaments: [],
        tournament: {},
        teams: [],
        gameFormats: [],
        seasons: [],
        fixtures: [],
        fixture: {},
    }),

    mutations: {
        setTournaments(state, payload) {
            state.tournaments = payload;
        },
        setTournament(state, payload) {
            state.tournament = payload;
        },
        setTeams(state, payload) {
            state.teams = payload;
        },
        setGameFormats(state, payload) {
            state.gameFormats = payload;
        },
        setSeasons(state, payload) {
            state.seasons = payload;
        },
        setFixtures(state, payload) {
            state.fixtures = payload;
        },
        setFixture(state, payload) {
            state.fixture = payload;
        },
    },

    getters: {
        getTournaments(state) {
            return state.tournaments;
        },
        getTournament(state) {
            return state.tournament;
        },
        getTeams(state) {
            return state.teams;
        },
        getGameFormats(state) {
            return state.gameFormats;
        },
        getSeasons(state) {
            return state.seasons;
        },
        getFixtures(state) {
            return state.fixtures;
        },
        getFixture(state) {
            return state.fixture;
        },
    },

}