export default {
    namespaced: true,
    state: () => ({
        battingTeam: {},
        bowlingTeam: {},
        batsmen: [],
        wicketKeeperID: 0,
        allBatsmen: [],
        bowlers: [],
        inning: {},
        strikers: [],
        bowler: [],


    }),
    mutations: {
        setBattingTeam(state, payload) {
            state.battingTeam = payload;
        },
        setBowlingTeam(state, payload) {
            state.bowlingTeam = payload;
        },
        setBatsmen(state, payload) {
            state.batsmen = payload;
        },
        setWicketKeeperID(state, payload) {
            state.wicketKeeperID = payload;
        },
        setAllBatsmen(state, payload) {
            state.allBatsmen = payload;
        },

        removeBatsmen(state, index) {
            state.batsmen.splice(index, 1);
        },


        removeBowler(state, index) {
            state.bowlers.splice(index, 1);
        },

        setBowlers(state, payload) {
            state.bowlers = payload;
        },

        setInning(state, payload) {
            state.inning = payload;
        },

        setStrikers(state, payload) {
            state.strikers = payload;
        },

        pushStrikers(state, payload) {
            state.strikers.push(payload);
        },

        pushBowlers(state, payload) {
            state.bowlers.push(payload);
        },
        pushBowler(state, payload) {
            state.bowler.push(payload);
        },

        setBowler(state, payload) {
            state.bowler = payload;
        }
    },
    getters: {
        getBattingTeam(state) {
            return state.battingTeam;
        },
        getBowlingTeam(state) {
            return state.bowlingTeam;
        },
        getBatsmen(state) {
            return state.batsmen;
        },

        getWicketKeeperID(state) {
            return state.wicketKeeperID;
        },

        getAllBatsmen(state) {
            return state.allBatsmen;
        },
        getBowlers(state) {
            return state.bowlers;
        },
        getInning(state) {
            return state.inning;
        },
        getStrikers(state) {
            return state.strikers;
        },

        getBowler(state) {
            return state.bowler;
        }
    }

}