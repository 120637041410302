import {createRouter, createWebHistory} from "vue-router";
import routes from "./routes";
import {isHasAccessTo, isLoggedIn, removeToken} from '@/services/User'

const history = createWebHistory();

const router = createRouter({
    history,
    routes,

});


router.beforeEach((to, from, next) => {


    if (to.path !== '/login') {

        // this route requires auth, check if logged in
        // if not, redirect to login page.

        if (isLoggedIn() && isHasAccessTo(to.meta.domain)) {


                next();



        } else {

            removeToken();

            next({
                path: '/login',
            })
        }
    } else {

        next() // make sure to always call next()!
    }

});


export default router;