export default {
    namespaced: true,
    state: () => ({
        tournaments: [],
        selectedTournament: {},
        teams: {},
        selectedTeams: [],
        selectedTeam: {},
        players: [],
        selectedPlayers: [],


    }),


    mutations: {
        setTournaments(state, payload) {
            state.tournaments = payload;
        },
        setTournament(state, payload) {

            state.selectedTournament = payload;

            if (state.selectedTeams.length > 0) {
                for (let i = 0; i < state.selectedTeams.length; i++) {
                    state.teams.data.push(state.selectedTeams[i]);
                }

            }

            state.selectedTeams = [];
            state.selectedTeam = {};

        },

        setTeams(state, payload) {
            state.teams = payload;
        },
        setTeamsData(state, payload) {
            state.teams.data = payload;
        },
        removeSetTeams(state, index) {
            state.teams.data.splice(index, 1);
        },
        addToSetTeams(state, payload) {
            state.teams.data.push(payload);
        },
        setAllSelectedTeams(state, payload) {
            state.selectedTeams = payload;
        },
        setSelectedTeams(state, payload) {
            state.selectedTeams.push(payload);
        },
        removeSetSelectedTeam(state, index) {
            state.selectedTeams.splice(index, 1);
        },
        setSelectedTeam(state, payload) {
            state.selectedTeam = payload;
        },

        setPlayers(state, payload) {
            state.players = payload;
        },
        setPlayersData(state, payload) {
            state.players.data = payload;
        },

        setSelectedPlayers(state, payload) {

            state.selectedPlayers.push(payload);
        },
        setAllSelectedPlayers(state, payload) {
            state.selectedPlayers = payload;
        },
        removeFromPlayers(state, index) {
            state.players.data.splice(index, 1);
        },
        addToPlayers(state, player) {
            state.players.data.push(player);
        },
        removeFromSelectedPlayers(state, index) {
            state.selectedPlayers.splice(index, 1);
        }

    },

    getters: {

        getTournaments(state) {
            return state.tournaments;
        },
        getTournament(state) {
            return state.selectedTournament;
        },
        getTeams(state) {
            return state.teams;
        },
        getSelectedTeams(state) {
            return state.selectedTeams;
        },
        getSelectedTeam(state) {
            return state.selectedTeam;
        },

        getPlayers(state) {
            return state.players;
        },

        getSelectedPlayers(state) {
            return state.selectedPlayers;
        },

    }
}