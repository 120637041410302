<template>
  <div>

    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>

import User, {removeToken, setToken} from "@/services/User";

const default_layout = 'defaultLayout'

export default {
  name: 'App',

  methods: {
    setAuth() {

      User.auth().then(response => {

        this.$store.commit('Auth/user', response.data.user);

        let data = {
          ttl: response.data.ttl,
          domains: response.data.domains,
        }
        setToken(data);

      }, error => {


        if (error.response.status === 401) {

          removeToken();

          this.$router.push({name: 'login'});

        }

      });
    },
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout);
    },
  },


  mounted() {

    this.setAuth();

  }

}
</script>
