import {createStore} from 'vuex';
import createPersistedState from "vuex-persistedstate";
import Auth from '@/store/modules/Auth'
import Global from "@/store/modules/Global";
import SetTournament from "@/store/modules/SetTournament";
import LaunchMatch from "@/store/modules/LaunchMatch";
import StartInning from "@/store/modules/StartInning";
import Score from "@/store/modules/Score";
import Fixture from "@/store/modules/Fixture";


export default createStore({
    // strict: true,

    modules: {
        Auth,
        Global,
        SetTournament,
        LaunchMatch,
        StartInning,
        Score,
        Fixture
    },

    plugins: [createPersistedState()],
});
