<template>
  <nav class="navbar navbar-expand-lg main-navbar">
    <div class="form-inline mr-auto">
      <ul class="navbar-nav mr-3">
        <li><a href="#" data-toggle="sidebar" class="nav-link nav-link-lg collapse-btn"><i
            class="fas fa-bars"></i></a></li>
        <li><a href="#" class="nav-link nav-link-lg fullscreen-btn">
          <i class="fas fa-expand"></i>
        </a>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav navbar-right">
      <li class="dropdown dropdown-list-toggle"><a href="#" data-toggle="dropdown"
                                                   class="nav-link nav-link-lg message-toggle beep"><i
          class="far fa-envelope"></i></a>
        <div class="dropdown-menu dropdown-list dropdown-menu-right">
          <div class="dropdown-header">Messages
            <div class="float-right">
              <a href="#">Mark All As Read</a>
            </div>
          </div>
          <div class="dropdown-list-content dropdown-list-message">
            <a href="#" class="dropdown-item">

                  <span class="dropdown-item-avatar text-white">
                    <img alt="image" src="" class="rounded-circle">
                  </span>

              <span class="dropdown-item-desc">
                    <span class="message-user">
                      Asghar Afghan
                    </span>
                    <span class="time messege-text">
                      Please send me my last match performance report!
                    </span>
                    <span class="time text-primary">2 Min Ago</span>
                  </span>
            </a>

          </div>
          <div class="dropdown-footer text-center">
            <a href="#">View All <i class="fas fa-chevron-right"></i></a>
          </div>
        </div>
      </li>
      <li class="dropdown dropdown-list-toggle"><a href="#" data-toggle="dropdown"
                                                   class="nav-link notification-toggle nav-link-lg beep"><i
          class="far fa-bell"></i></a>
        <div class="dropdown-menu dropdown-list dropdown-menu-right">
          <div class="dropdown-header">Notifications
            <div class="float-right">
              <a href="#">Mark All As Read</a>
            </div>
          </div>
          <div class="dropdown-list-content dropdown-list-icons">
            <a href="#" class="dropdown-item dropdown-item-unread">
              <span class="dropdown-item-icon bg-primary text-white">
                <i class="fas fa-code"></i>
              </span>
              <span class="dropdown-item-desc">
                    Cricket update is available now!
                    <span class="time text-primary">2 Min Ago</span>
              </span>
            </a>

          </div>
          <div class="dropdown-footer text-center">
            <a href="#">View All <i class="fas fa-chevron-right"></i></a>
          </div>
        </div>
      </li>


      <li class="dropdown">
        <a href="#"
           data-toggle="dropdown"
           class="nav-link dropdown-toggle nav-link-lg nav-link-user">
          <img alt="image" :src="$store.state.Global.rootCDN+ $store.state.Auth.user.photo"
               class="user-img-radious-style">
          <span class="d-sm-none d-lg-inline-block"></span></a>

        <div class="dropdown-menu dropdown-menu-right">

          <div class="dropdown-title">
            {{ $store.state.Auth.user.name }}
            <small>

              {{ $store.state.Auth.user.email }}
            </small>
          </div>

          <a href="#" class="dropdown-item has-icon">
            <i class="far fa-user"></i> Profile
          </a>
          <a href="#" class="dropdown-item has-icon">
            <i class="fas fa-bolt"></i> Activities
          </a>
          <a href="#" class="dropdown-item has-icon">
            <i class="fas fa-cog"></i> Settings
          </a>
          <router-link :to="{name: 'users'}" class="dropdown-item has-icon" v-if="isHasAccessTo('users')">
            <i class="fas fa-users"></i> Users
          </router-link>
          <div class="dropdown-divider"></div>
          <!---->
          <a href="#" class="dropdown-item has-icon text-danger" @click.prevent="logout">
            <i class="fas fa-sign-out-alt"></i> Logout
          </a>

        </div>
      </li>
    </ul>
  </nav>
</template>
<script>
import User, {isHasAccessTo, removeToken} from '../../../services/User'

export default {
  methods: {

    logout() {
      User.logout().then(() => {

        removeToken();

        this.$router.push({name: 'login'});

      })
    },
    isHasAccessTo,
  },
  mounted() {
    isHasAccessTo();
  }
}
</script>
<style scoped>

@media prent{
  nav{
    display: none;
  }
}
</style>
