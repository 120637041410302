import API from './API'
import CSRF from './CSRF'


export default {

    async login(form) {
        await CSRF.getCookie();

        return API.post('/auth/login', {
            'email': form.email,
            'password': form.password
        });

    },

    auth() {
        return API.get('/auth/user');
    },

    logout() {
        return API.get('/auth/logout');
    }


}

export function getDomains() {

    return API.get('auth/user/domains');

}

export function setToken(data) {

    const auth = {
        value: 'true',
        expiry: Date.now() + data.ttl,
    };

    const domains = {
        value: data.domains,
        expiry: Date.now() + data.ttl,
    };




    localStorage.setItem('auth', JSON.stringify(auth));

    localStorage.setItem('domains', JSON.stringify(domains));

}

export function removeToken() {
    const item = {
        value: 'false',
        expiry: Date.now(),
    }

    localStorage.setItem('auth', JSON.stringify(item));

    localStorage.removeItem('domains');
}

export function isLoggedIn() {

    const itemStr = localStorage.getItem('auth')
    // if the item doesn't exist, return null
    if (!itemStr) {
        return false;
    }

    const item = JSON.parse(itemStr)
    const now = Date.now();

    // compare the expiry time of the item with the current time
    if (now > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem('auth')
        return false
    }


    return item.value === 'true';
}

export function isHasAccessTo(domain) {

    const domainStr = localStorage.getItem('domains')

    // if the item doesn't exist, return null
    if (!domainStr) {
        return false;
    }

    const domainObj = JSON.parse(domainStr);

    let domainsList = domainObj.value;

    const now = Date.now();

    // compare the expiry time of the item with the current time
    if (now > domainObj.expiry) {

        // If the item is expired, delete the item from storage
        // and return null

        localStorage.removeItem('domains')

        return false

    } else {

        return !!domainsList.find(element => element === domain);

    }


}


export function loadUsers() {
    return API.get('/users')
}

export function save(data) {
    return API.post('/users/store', data);
}

export function destroy(id) {
    return API.post('/users/delete', {
        'id': id,
    });
}


export function edit(id) {
    return API.post('/users/edit', {'id': id});
}

export function update(data) {
    return API.post('users/update', data);
}

export function newPassword(data) {
    return API.post('/users/change-password', data);
}

export function loginWithID(data) {
    return API.post('/users/login-with-id', data);
}
