export default [
    {

        path: "/scoring-and-performance/lookup",
        name: "scoring-and-performance-lookup",
        component: () =>
            import("../components/scoringAndPerformance/lookup/Index.vue"),


        meta: {
            layout: 'defaultLayout',
            module: 'scoring-and-performance',
            domain: 'scoring-and-performance-lookup',
            requireAuth: true
        },
    },
    {

        path: "/scoring-and-performance/manage-teams",
        name: "scoring-and-performance-manage-teams",
        component: () =>
            import("../components/scoringAndPerformance/manageTeams/Index.vue"),
        meta: {
            layout: 'defaultLayout',
            module: 'scoring-and-performance',
            domain: 'scoring-and-performance-manage-teams',
            requireAuth: true
        },
    },
    {

        path: "/scoring-and-performance/manage-players",
        name: "scoring-and-performance-manage-players",
        component: () =>
            import("../components/scoringAndPerformance/managePlayers/Index.vue"),
        meta: {
            layout: 'defaultLayout',
            module: 'scoring-and-performance',
            domain: 'scoring-and-performance-manage-players',
            requireAuth: true
        },
    },
    {

        path: "/scoring-and-performance/set-tournament",
        name: "scoring-and-performance-set-tournament",
        component: () =>
            import("../components/scoringAndPerformance/setTournament/Index.vue"),
        meta: {
            layout: 'defaultLayout',
            module: 'scoring-and-performance',
            domain: 'scoring-and-performance-set-tournament',
            requireAuth: true
        },
    },
    {

        path: "/scoring-and-performance/create-fixture",
        name: "scoring-and-performance-create-fixture",
        component: () =>
            import("../components/scoringAndPerformance/createFixture/Index.vue"),
        meta: {
            layout: 'defaultLayout',
            module: 'scoring-and-performance',
            domain: 'scoring-and-performance-create-fixture',
            requireAuth: true
        },
    },
    {

        path: "/scoring-and-performance/launch-match/:section",
        name: "scoring-and-performance-launch-match",
        component: () =>
            import("../components/scoringAndPerformance/launchMatch/Index.vue"),
        meta: {
            layout: 'defaultLayout',
            module: 'scoring-and-performance',
            domain: 'scoring-and-performance-launch-match',
            requireAuth: true
        },
    },

]