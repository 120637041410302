import {createApp} from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {VueCookieNext} from 'vue-cookie-next'
import Store from '@/store/store'
import App from '@/App.vue'
import Default from '@/components/layouts/defaultLayout.vue'
import Login from '@/components/layouts/login.vue'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import router from "@/router/router.js";
import VueHeadSlot from 'vue-head-slot'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'
import KProgress from 'k-progress-v3';
// import PrimeVue from 'primevue/config';

// import 'primevue/resources/themes/saga-blue/theme.css'       //theme
// import 'primevue/resources/primevue.min.css'                 //core css
// import 'primeicons/primeicons.css'                           //icons

const app = createApp(App);

app
    .component('defaultLayout', Default)
    .component('login', Login)
    .component('v-select',VueSelect)
    .component('k-progress', KProgress);
app
    .use(router, axios, VueAxios)
    .use(Store)
    .use(VueCookieNext)
    .use(VueHeadSlot)
    .use(Toast)
    .use(VueSweetalert2)
    // .use(PrimeVue)
    .mount('#app');

