export default {
    namespaced: true,
    state: () => ({
        last_over: 0,
        last_bowl: 0,
        recent_runs: [],
        batsmenPerformance: [],
        batsmenLegalRunsScored: [],
        batsmenFacedLegalBalls: [],
        bowlerPerformance: [],
        bowlerGivenRuns: [],
        bowlerLegalRunsGiven: [],
        bowlerLegalBallsBowled: [],
        legalBallsBowled: 0,
        totalInningRuns: 0,
        totalInningWickets: 0,
        lastScoreId: 0,
    }),
    mutations: {
        setLastOver(state, payload) {
            state.last_over = payload;
        },
        setLastBowl(state, payload) {
            state.last_bowl = payload;
        },
        setBatsmenPerformance(state, payload) {
            state.batsmenPerformance = payload;
        },
        setBatsmenLegalRunsScored(state, payload) {
            state.batsmenLegalRunsScored = payload;
        },
        setBatsmenFacedLegalBalls(state, payload) {
            state.batsmenFacedLegalBalls = payload;
        },
        setBowlerPerformance(state, payload) {
            state.bowlerPerformance = payload;
        },
        setBowlerGivenRuns(state, payload) {
            state.bowlerGivenRuns = payload;
        },
        setBowlerLegalRunsGiven(state, payload) {
            state.bowlerLegalRunsGiven = payload;
        },
        setBowlerLegalBallsBowled(state, payload) {
            state.bowlerLegalBallsBowled = payload;
        },
        setLegalBallsBowled(state, payload) {
            state.legalBallsBowled = payload;
        },
        setTotalInningRuns(state, payload) {
            state.totalInningRuns = payload;
        },
        setTotalInningWickets(state, payload) {
            state.totalInningWickets = payload;
        },
        setRecentRuns(state, payload) {
            state.recent_runs = payload;
        },
        setLastScoreId(state, payload) {
            state.lastScoreId = payload;
        },

    },
    getters: {
        getLastOver(state) {
            return state.last_over;
        },
        getLastBowl(state) {
            return state.last_bowl;
        },
        getBatsmenPerformance(state) {
            return state.batsmenPerformance;
        },
        getBatsmenLegalRunsScored(state) {
            return state.batsmenLegalRunsScored;
        },
        getBatsmenFacedLegalBalls(state) {
            return state.batsmenFacedLegalBalls;
        },
        getBowlerPerformance(state) {
            return state.bowlerPerformance;
        },
        getBowlerGivenRuns(state) {
            return state.bowlerGivenRuns;
        },

        getBowlerLegalRunsGiven(state) {
            return state.bowlerLegalRunsGiven;
        },
        getBowlerLegalBallsBowled(state) {
            return state.bowlerLegalBallsBowled;
        },
        getLegalBallsBowled(state) {
            return state.legalBallsBowled;
        },

        getTotalInningRuns(state) {
            return state.totalInningRuns;
        },
        getTotalInningWickets(state) {
            return state.totalInningWickets;
        },
        getRecentRuns(state) {
            let recent_runs = [];

            for( let i = 0; i < state.recent_runs.length; i++){
                if(i < 11){
                    recent_runs.push(state.recent_runs[i]);
                }
                else {
                    break;
                }
            }
            return recent_runs;
        },
        getLastScoreId(state) {
            return state.lastScoreId;
        }
    },
}