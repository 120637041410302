<template>
  <section class="section">

    <head-slot>
      <title>Afghanistan Cricket Board | Login</title>
    </head-slot>

    <div class="container mt-5">
      <div class="row" style="margin-top: 70px;">
        <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <div class="card card-primary">
            <div class="card-header" style="background-color: #045BA6 ">

              <img :src="publicPath + 'assets/labels/en-new.png'" alt="" style="height: 60px; margin: 0 auto;">


            </div>
            <div class="row">
              <div class="col-12 text-center text-white" style="background-color: #045BA6; border-radius: 5px;">
                Use credentials of ACB to login
              </div>
            </div>
            <div class="card-body">

              <!--              <p class="text-danger"> {{ errors.credential }} </p>-->

              <form method="POST" action="#" class="needs-validation" novalidate="">

                <div class="form-group">
                  <label for="email">Email</label>
                  <input id="email" type="email" v-model="form.email" class="form-control" name="email" tabindex="1"
                         required autofocus>

                  <span class="text-danger" v-if="validationError.email"> {{ validationError.email[0] }} </span>
                  <span class="text-danger" v-if="validationError.credential"> {{
                      validationError.credential[0]
                    }} </span>

                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input id="password" type="password" v-model="form.password" class="form-control" name="password"
                         tabindex="2" required>
                  <span class="text-danger" v-if="validationError.password"> {{ validationError.password[0] }} </span>

                </div>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" name="remember" class="custom-control-input" tabindex="3" id="remember-me">
                    <label class="custom-control-label" for="remember-me">Remember Me</label>
                  </div>
                </div>
                <div class="form-group">

                  <button type="submit" @click.prevent="login" class="btn btn-primary btn-lg btn-block"
                          tabindex="4">
                    Login
                  </button>

                </div>
              </form>

            </div>
          </div>
          <div class="mt-5 text-muted text-center">
            Afghanistan Cricket Board

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


import User from '../services/User'
import {removeToken, setToken} from "@/services/User.js";
import {useToast} from "vue-toastification";

export default {
  name: 'AppLogin',

  data() {
    return {
      toast: useToast(),
      form: {
        email: '',
        password: '',
      },
      validationError: [],
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  },

  methods: {

    setAuth() {


      User.auth().then(response => {

        this.$store.commit('Auth/user', response.data.user);

        let data = {
          ttl: response.data.ttl,
          domains: response.data.domains,
        }

        setTimeout(this.push(), 3000);

        setToken(data);


      }, error => {


        if (error.response.status === 401) {

          removeToken();

          this.$router.push({name: 'login'});
        }

      });
    },

    push() {

      this.$router.push('/');
    },
    login() {

      this.toast.info('processing...');

      User.login(this.form).then(() => {
        this.toast.clear();

        this.toast.success('You are successfully authenticated');


        this.setAuth();


      }, error => {


        this.toast.clear();


        this.toast.error(error.response.data.message);

        if (error.response.status === 422) {
          this.validationError = error.response.data.errors;
        }

      });
    }

  }

}

</script>
<style scoped>
.card-primary {
  border-top: none !important;
  border-bottom: 2px solid #045BA6;
}
</style>
