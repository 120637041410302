export default [
    {

        path: "/report/top-performers",
        name: "report-top-performers",
        component: () =>
            import("../components/reports/performance/Index.vue"),

        meta: {
            layout: 'defaultLayout',
            module: 'report',
            domain: 'report-top-performers',
            requireAuth: true
        },
    },
    {

        path: "/report/top-performers/print",
        name: "report-top-performers-print",
        component: () =>
            import("../components/reports/performance/print.vue"),

        meta: {
            layout: 'login',
            module: 'report',
            domain: 'report-top-performers',
            requireAuth: true
        },
    },
];