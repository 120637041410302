export default {
    namespaced: true,
    state: () => ({
        fixtures: [],
        fixture: {},
        fixtureTeams: [],
        fixtureTeam: {},
        teamPlayers: [],
        teamSelectedPlayers: [],
        yesTheyWon: false,
        electedTo: {},

    }),


    mutations: {
        setFixtures(state, payload) {
            state.fixtures = payload;
        },
        setFixture(state, payload) {

            state.fixture = payload;
        },
        setFixtureTeams(state, payload) {
            state.fixtureTeams = payload;
        },
        setFixtureTeam(state, payload) {
            state.fixtureTeam = payload;
        },
        setTeamPlayers(state, payload) {
            state.teamPlayers = payload;
        },
        setTeamPlayer(state, payload) {
            state.teamPlayers.push(payload);
        },
        setTeamAllSelectedPlayers(state, payload) {
            state.teamSelectedPlayers = payload;
        },
        setTeamSelectedPlayers(state, payload) {
            state.teamSelectedPlayers.push(payload);
        },
        removeTeamPlayer(state, index) {
            state.teamPlayers.splice(index, 1);
        },
        removeTeamSelectedPlayer(state, index) {
            state.teamSelectedPlayers.splice(index, 1);
        },
        setYesTheyWon(state, payload) {
            state.yesTheyWon = payload;
        },
        setElectedTo(state, payload) {
            state.electedTo = payload;
        }

    },
    getters: {
        getFixtures(state) {
            return state.fixtures;
        },
        getFixture(state) {

            return state.fixture;
        },
        getFixtureTeams(state) {
            return state.fixtureTeams;
        },
        getFixtureTeam(state) {
            return state.fixtureTeam;
        },
        getTeamPlayers(state) {
            return state.teamPlayers;
        },
        getTeamSelectedPlayers(state) {
            return state.teamSelectedPlayers;
        },
        getYesTheyWon(state) {
            return state.yesTheyWon;
        },
        getElectedTo(state) {
            return state.electedTo;
        }
    },
};