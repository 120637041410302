export default {
    namespaced: true,
    state: () => ({
        user: {

        },
        login: false,
    }),
    mutations: {
        user(state, payload){
            state.user = payload;
        },
        login(state, payload){
            state.login = payload;
        }
    },
    actions: {},
    getters: {}
}