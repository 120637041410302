<template>
  <footer class="main-footer">
    <div class="footer-left">
      Copyright &copy; 2020
      <div class="bullet"></div>
      Design By <a href="#"> ACB IT Department </a>
    </div>
    <div class="footer-right">
    </div>
  </footer>
</template>
<script>
export default {

}
</script>
<style scoped>
  .main-footer{
    position: fixed;
    bottom: 0;
    z-index: 1;
  }

  @media print {
    footer{
      display: none;
    }
  }

</style>
