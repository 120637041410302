<template>
  <div class="main-sidebar sidebar-style-2">
    <aside id="sidebar-wrapper">
      <div class="sidebar-brand">
        <router-link to="/">

          <span class="logo-name">ACB</span>
        </router-link>
      </div>
      <ul class="sidebar-menu">
        <li class="menu-header">Main</li>

        <li>
          <router-link to="/" class="nav-link" href="#" active-class="parent-active" v-if="isHasAccessTo('dashboard')">
            <i class="fas fa-home"></i>
            <span>Dashboard</span>
          </router-link>
        </li>

        <li class="dropdown">

          <a href="#" class="nav-link has-dropdown"
             :class="{'parent-active': $route.meta.module === 'scoring-and-performance'}">
            <i class="fas fa-arrow-circle-right"></i>
            <span>Scoring & Performance</span>
          </a>

          <ul class="dropdown-menu" :class="{'display-block': $route.meta.module === 'scoring-and-performance'}">
            <li>
              <router-link :to="{ name: 'scoring-and-performance-lookup' }" class="nav-link" active-class="active"
                           v-if="isHasAccessTo('scoring-and-performance-lookup')">
                Lookup
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'scoring-and-performance-manage-players' }" class="nav-link"
                           active-class="active"
                           v-if="isHasAccessTo('scoring-and-performance-manage-players')">
                Manage Players
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'scoring-and-performance-manage-teams' }" class="nav-link" active-class="active"
                           v-if="isHasAccessTo('scoring-and-performance-manage-teams')">
                Manage Teams
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'scoring-and-performance-set-tournament' }" class="nav-link"
                           active-class="active"
                           v-if="isHasAccessTo('scoring-and-performance-set-tournament')">
                Set Tournament
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'scoring-and-performance-create-fixture' }" class="nav-link"
                           active-class="active"
                           v-if="isHasAccessTo('scoring-and-performance-create-fixture')">
                Create Fixture
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'scoring-and-performance-launch-match', params:{section: 'playing-11'} }"
                           class="nav-link" active-class="active"
                           :class="{'active': $route.params.section === 'start-inning'}"
                           v-if="isHasAccessTo('scoring-and-performance-launch-match')">
                Launch Match
              </router-link>
            </li>

          </ul>
        </li>

        <li class="dropdown">

          <a href="#" class="nav-link has-dropdown"
             :class="{'parent-active': $route.meta.module === 'Fitness'}">
            <i class="fas fa-arrow-circle-right"></i>
            <span>Fitness</span>
          </a>

          <ul class="dropdown-menu" :class="{'display-block': $route.meta.module === 'scoring-and-performance'}">
            <li>
              <router-link :to="{ name: 'scoring-and-performance-lookup' }" class="nav-link" active-class="active"
                           v-if="isHasAccessTo('scoring-and-performance-lookup')">
                Lookup
              </router-link>
            </li>


          </ul>
        </li>
        <li class="dropdown">

          <a href="#" class="nav-link has-dropdown"
             :class="{'parent-active': $route.meta.module === 'Fitness'}">
            <i class="fas fa-arrow-circle-right"></i>
            <span>Discipline</span>
          </a>

          <ul class="dropdown-menu" :class="{'display-block': $route.meta.module === 'scoring-and-performance'}">
            <li>
              <router-link :to="{ name: 'scoring-and-performance-lookup' }" class="nav-link" active-class="active"
                           v-if="isHasAccessTo('scoring-and-performance-lookup')">
                Lookup
              </router-link>
            </li>


          </ul>
        </li>
        <li class="dropdown">

          <a href="#" class="nav-link has-dropdown"
             :class="{'parent-active': $route.meta.module === 'Fitness'}">
            <i class="fas fa-arrow-circle-right"></i>
            <span>Reports</span>
          </a>

          <ul class="dropdown-menu" :class="{'display-block': $route.meta.module === 'report'}">

            <li>
              <router-link :to="{ name: 'report-top-performers' }" class="nav-link" active-class="active"
                           v-if="isHasAccessTo('report-top-performers')">
                Top Performers
              </router-link>
            </li>


          </ul>
        </li>


      </ul>
    </aside>
  </div>
</template>
<script>
import {isHasAccessTo} from "@/services/User";

export default {
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    isHasAccessTo,
    dropDownOpen() {


    }
  },
  mounted() {
    // isHasAccessTo();
    let Script = document.createElement('script')
    Script.setAttribute('src', this.publicPath + 'assets/js/scripts.js')
    document.head.appendChild(Script)
  }
}
</script>
<style scoped>
.parent-active {
  background-color: #023C6A !important;
}

a.active, a.active:hover {
  background-color: #005199 !important;
}

.display-block {
  display: block;
}

@media print {
  .main-sidebar{
    display: none;
  }
}


</style>
