import Login from "../components/Login.vue";
import scoringAndPerformance from './scoringAndPerformance';
import reports from "@/router/reports";

const routes = [
    {
        path: "/",
        name: "dashboard",
        component: () =>
            import("../components/dashboard/Index.vue"),

        meta: {layout: 'defaultLayout', module: 'dashboard', domain: 'dashboard', requireAuth: true},
    },

    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {layout: 'login', requireAuth: true},


    },


    {
        path: "/users",
        name: "users",
        component: () =>
            import("../components/users/Index.vue"),
        meta: {layout: 'defaultLayout', domain: 'users', requireAuth: true},
    },
    ...scoringAndPerformance,
    ...reports,
];


export default routes;