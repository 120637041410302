<template>
  <div>
    <app-head/>
    <!--    <div class="loader"></div>-->
    <div id="app">
      <div class="main-wrapper main-wrapper-1">
        <div class="navbar-bg"></div>

        <app-nav-bar/>

        <app-side-bar/>


        <div class="main-content">



          <slot/>


        </div>

        <app-footer/>


      </div>
    </div>

  </div>
</template>
<script>

import Head from './parts/Head.vue'
import NavBar from './parts/NavBar.vue';
import SideBar from './parts/SideBar.vue';
import Footer from './parts/Footer.vue';


export default {
  components: {

    appHead: Head,
    appNavBar: NavBar,
    appSideBar: SideBar,
    appFooter: Footer,


  },

  // head: {
  //   // bodyAttrs: {
  //   //   class: 'dark-sidebar'
  //   // }
  // }


}
</script>
<style>

</style>
